<!--
  TODO : 버튼 공통 모듈
-->
<template>
  <q-btn
    class="custom-btn"
    align="center"
    :disable="disabled"
    :loading="loading"
    :outline="outline"
    :size="size"
    :flat="flat"
    :color="getColors()"
    :round="isIcon"
    :text-color="flat ? getFlatTextColors() : getTextColors()"
    :label="label"
    :icon="icon ? icon : void 0"
    @click.prevent="btnClicked">
    <slot name="tooltip"></slot>
  </q-btn>
</template>

<script>
export default {
  /* attributes: name, components, props, data */
  name: 'c-btn',
  props: {
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    mappingType: {
      type: String,
      default: '',
    },
    param: {
      type: [Object, Array],
      default: null,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    isSubmit: {
      // 버튼 클릭 후 처리를 계속 진행할지 여부
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    color: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showLoading: {
      type: Boolean,
      default: true,
    },
    isFileRequestPost: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    textColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    isIcon() {
      return this.label === '' && this.icon !== '';
    },
  },
  watch: {
    isSubmit() {
      this.callButtonAction();
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  mounted() {
  },
  /* methods */
  methods: {
    getColors() {
      var returnColor = '';
      if (this.color !== '') {
        returnColor = this.color;
      } else {
        switch(this.icon) {
          case 'add': // 추가/신규
          case 'add_circle': // 추가/신규
            returnColor = 'amber-7';
            break;
          case 'search': // 검색
            returnColor = 'teal-5';
            break;
          case 'delete_forever': // 삭제
          case 'remove': // 삭제
          case 'delete': // 삭제
            returnColor = 'red';
            break;
          case 'save': // 저장
            returnColor = 'blue-7';
            break;
          case 'check': // 선택
            returnColor = 'grey-5';
            break;
          case 'autorenew': // 초기화
            returnColor = 'purple';
            break;
          case 'restart_alt': // 개정
            returnColor = 'amber-7';
            break;
          case 'help': // help
            returnColor = 'deep-purple-6';
            break;
          case 'save_alt': // 복사
            returnColor = 'green-7';
            break;
          default:
            returnColor = 'blue-grey-4';
            break;
        }
      }

      return returnColor;
    },
    getTextColors() {
      var returnColor = '';
      if (this.textColor !== '') {
        returnColor = this.textColor;
      } else {
        returnColor = this.getColors() === 'white' ? 'black' : 'white'
      }

      if (this.flat) {
        returnColor = 'black';
      }

      return returnColor;
    },
    getFlatTextColors() {
      var returnColor = '';
      if (this.color !== '') {
        returnColor = this.color;
      } else {
        switch(this.icon) {
          case 'add': // 추가/신규
          case 'add_circle': // 추가/신규
            returnColor = 'amber-7';
            break;
          case 'search': // 검색
            returnColor = 'teal-5';
            break;
          case 'delete_forever': // 삭제
          case 'remove': // 삭제
          case 'delete': // 삭제
            returnColor = 'red';
            break;
          case 'save': // 저장
            returnColor = 'blue-7';
            break;
          case 'check': // 선택
            returnColor = 'purple-custom1';
            break;
          case 'autorenew': // 초기화
            returnColor = 'purple';
            break;
          case 'restart_alt': // 개정
            returnColor = 'amber-7';
            break;
          case 'help': // help
            returnColor = 'deep-purple-6';
            break;
          case 'save_alt': // 복사
            returnColor = 'green-7';
            break;
          default:
            returnColor = 'blue-grey-4';
            break;
        }
      }
      return returnColor;
    },
    /**
     * 버튼 클릭 처리
     *  - 저장 버튼 클릭시 저장전 유효성 검사
     */
    btnClicked() {
      let self = this;
      if (this.showLoading) {
        this.loading = !this.loading;
      } else {
        this.loading = false;
      }
      // 저장일 경우 유효성 검사
      if (this.url) {
        self.closeLoading();
        this.$emit('beforeAction');
      } else {
        self.closeLoading();
        this.callButtonAction();
      }
    },
    /**
     * 버튼 행위 정의
     */
    callButtonAction() {
      let self = this;
      // ajax action
      if (this.url) {
        this.$http.url = this.url;
        this.$http.param = this.param;
        this.$http.type = this.mappingType;
        this.$http.isLoading = true;
        this.$http.isFileRequestPost = this.isFileRequestPost;

        this.$http.request(
          _result => {
            self.$emit('btnCallback', _result);
          },
          () => {
            // this.$emit(
            //   'btnClickedErrorCallback',
            //   _error ? _error.message : _error
            // );
          }
        );
      } else {
        this.$emit('btnClicked');
      }
    },
    /**
     * 버튼 로딩바 0.5초 후 제거
     */
    closeLoading() {
      setTimeout(() => (this.loading = false), 300);
    },
  },
};
</script>

