import Vue from 'vue'
import Router from 'vue-router'
// import { LFeatureGroup } from 'vue2-leaflet';

Vue.use(Router);

export const asyncRouterMap = [
  /** When your routing table is too long, you can split it into small modules**/
  {
    path: '/404',
    name: '404',
    hidden: true,
    meta: { layout: 'userpages', menulog: 'N' },
    component: () => import('@/pages/error/404.vue'),
  },
  {
    path: '/login',
    name: 'login',
    hidden: true,
    meta: { layout: 'userpages', menulog: 'N' },
    component: () => import('@/pages/login/Login.vue'),
  },
  {
    path: '/',
    name: 'main',
    title: '메인화면',
    icon: 'dashboard',
    hidden: true,
    meta: { layout: 'default', editable: true, menulog: 'N' },
    component: () => import('@/pages/main/index.vue'),
  },
  // {
  //   path: '/sop/swp/safeWorkPermit',
  //   name: 'MENU000045',
  //   title: '작업허가서',
  //   icon: 'home_repair_service',
  //   meta: { layout: 'default', editable: true, },
  //   component: () => import('@/pages/sop/swp/safeWorkPermit.vue'),
  // },
  // {
  //   path: '/sop/swp/safeWorkPermitVendor',
  //   name: 'safeWorkPermitVendor',
  //   title: '업체 작업허가서',
  //   icon: 'home_repair_service',
  //   meta: { layout: 'default', editable: true, },
  //   component: () => import('@/pages/sop/swp/safeWorkPermitVendor.vue'),
  // },
  // {
  //   path: '/sop/psr/preStartupReview',
  //   name: 'MENU000054',
  //   title: '가동전점검',
  //   icon: 'engineering',
  //   meta: { layout: 'default', editable: true, },
  //   component: () => import('@/pages/sop/psr/preStartupReview.vue'),
  // },
  // {
  //   path: '/sai/patrol/patrol',
  //   name: 'MENU000220',
  //   title: '순회점검',
  //   icon: 'change_circle',
  //   meta: { layout: 'default', editable: true, },
  //   component: () => import('@/pages/sai/patrol/patrol.vue'),
  // },
  // {
  //   path: '/sop/ibm/impr',
  //   name: 'MENU000068',
  //   title: '개선관리',
  //   icon: 'construction',
  //   meta: { layout: 'default', editable: true, },
  //   component: () => import('@/pages/sop/ibm/impr.vue'),
  // },
  // {
  //   path: '/sop/ibm/imprVendor',
  //   name: 'imprVendor',
  //   title: '협력업체 개선관리',
  //   icon: 'construction',
  //   meta: { layout: 'default', editable: true, },
  //   component: () => import('@/pages/sop/ibm/imprVendor.vue'),
  // },
  // {
  //   path: '/sop/ibm/imprSupple',
  //   name: 'MENU000268',
  //   title: '개선 유효성평가',
  //   icon: 'construction',
  //   meta: { layout: 'default', editable: true, },
  //   component: () => import('@/pages/sop/ibm/imprSupple.vue'),
  // },
  // {
  //   path: '/sop/mim/inspection/equipmentInspection',
  //   name: 'MENU000044',
  //   title: '설비점검결과',
  //   icon: 'plumbing',
  //   meta: { layout: 'default', editable: true, },
  //   component: () => import('@/pages/sop/mim/inspection/equipmentInspection.vue'),
  // },
  // {
  //   path: '/sop/mim/inspection/equipmentDailyInspection',
  //   name: 'MENU000238',
  //   title: '일상점검결과',
  //   icon: 'engineering',
  //   meta: { layout: 'default', editable: true, },
  //   component: () => import('@/pages/sop/mim/inspection/equipmentDailyInspection.vue'),
  // },
  // {
  //   path: '/sop/mim/inspection/safetyCheck',
  //   name: 'MENU000252',
  //   title: '관리감독자점검',
  //   icon: 'manage_accounts',
  //   meta: { layout: 'default', editable: true, },
  //   component: () => import('@/pages/sop/mim/inspection/safetyCheck.vue'),
  // },
  // {
  //   path: '/sop/edu/result/educationResult',
  //   name: 'MENU000048',
  //   title: '교육결과',
  //   icon: 'school',
  //   meta: { layout: 'default', editable: true, },
  //   component: () => import('@/pages/sop/edu/result/educationResult.vue'),
  // },
  // {
  //   path: '/sop/iim/near/nearAccident',
  //   name: 'MENU000059',
  //   title: '아차사고',
  //   icon: 'personal_injury',
  //   meta: { layout: 'default', editable: true, },
  //   component: () => import('@/pages/sop/iim/near/nearAccident.vue'),
  // },
  // {
  //   path: '/sop/cto/cto',
  //   name: 'MENU000147',
  //   title: '안전관찰',
  //   icon: 'change_circle',
  //   meta: { layout: 'default', editable: true, },
  //   component: () => import('@/pages/sop/cto/cto.vue'),
  // },
  // {
  //   path: '/hea/jss/jobStressAssessment',
  //   name: 'MENU000367',
  //   title: '직무스트레스',
  //   icon: 'psychology_alt',
  //   meta: { layout: 'default', editable: true, },
  //   component: () => import('@/pages/hea/jss/jobStressAssessment.vue'),
  // },
  // {
  //   path: '/sys/appr/doc/apprTodo',
  //   name: 'MENU000156',
  //   title: '결재할 문서',
  //   icon: 'assignment_turned_in',
  //   meta: { layout: 'default' },
  //   component: () => import('@/pages/sys/appr/doc/apprTodo.vue'),
  // },
  // {
  //   path: '/sys/appr/qrreader',
  //   name: 'MENU000999',
  //   title: 'QR리더기',
  //   icon: 'qr_code',
  //   meta: { layout: 'default' },
  //   component: () => import('@/pages/sys/appr/qrreader.vue'),
  // },
  // {
  //   path: '/imsi/sample/wo002',
  //   name: 'MENU000919',
  //   title: '작업지시(계획)서',
  //   icon: 'manage_accounts',
  //   meta: { layout: 'default', editable: true, },
  //   component: () => import('@/pages/imsi/sample/wo002.vue'),
  // },
  // {
  //   path: '/fft/fireFightingCheck',
  //   name: 'MENU000382',
  //   title: '소방설비점검',
  //   icon: 'firefighting',
  //   meta: { layout: 'default', editable: true, },
  //   component: () => import('@/pages/fft/fireFightingCheck.vue'),
  // },
];

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => {
		if (err.name !== 'NavigationDuplicated') throw err;
	});
};

export default new Router({
    scrollBehavior() {
        return window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    routes: asyncRouterMap,
    mode: 'history', // # 제거
})
