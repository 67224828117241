<!--
  TODO : 버튼 공통 모듈
-->
<template>

  <div class="map-marker-container" :class="isSelected ? 'selected-container' : ''">
    <div v-if="isImg" class='imgPin' :class="blink ? 'animatedBadge' : ''"> 
      <q-img
        class="img"
        :src="imgSrc"
      >
      </q-img>
    </div>
    <div v-else class='pin'>
    </div>
    <div :class="isImg ? 'imgPulse' : 'pulse'"></div>
    <slot name="innerMark"></slot>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  /* attributes: name, components, props, data */
  name: 'CMapMarker',
  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },
    imgSrc: {
      type: String,
      default: "require('@/assets/images/xxx.png')"
    },
    isImg: {
      type: Boolean,
      default: false,
    },
    blink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  mounted() {
    // EF4040
  },
  /* methods */
  methods: {
  },
};
</script>
<style lang="sass">
.map-marker-container
  .pin
    width: 30px
    height: 30px
    border-radius: 50% 50% 50% 0
    background: #B2A59B
    position: absolute
    transform: rotate(-45deg)
    left: 65%
    // top: 50%
    margin: -20px 0 0 -20px
    animation-name: bounce
    animation-fill-mode: both
    animation-duration: 1s
    &:after
      content: ''
      width: 14px
      height: 14px
      margin: 8px 0 0 8px
      background: #ffffff
      // background: #2F2F2F
      position: absolute
      border-radius: 50%

  .imgPin
    width: 50px
    height: 50px
    position: absolute
    border-radius: 50%
    border-width: 1px
    border-style: solid
    // border-color: grey
    border-color: #4287f5
    // background-color: grey
    transform: rotate(0) !important
    left: 59%
    // top: 50%
    margin: -17px 0 0 -20px
    .img
      border-radius: 50%
      animation: rotateImg 6s linear infinite
      // width: 45px
      // height: 45px
      transform-origin: 50% 50%

  .imgPulse
    background: #abcbff
    border-radius: 50%
    height: 14px
    width: 14px
    position: absolute
    left: 90%
    top: 100%
    margin: 11px 0px 0px -12px
    transform: rotateX(55deg)
    z-index: -2
    &:after
      content: ""
      border-radius: 50%
      height: 40px
      width: 40px
      position: absolute
      margin: -13px 0 0 -13px
      animation: pulsate 1s ease-out
      animation-iteration-count: infinite
      opacity: 0.0
      box-shadow: 0 0 1px 2px #abcbff
      animation-delay: 1.1s

  .pulse
    background: rgba(0,0,0,0.2)
    border-radius: 50%
    height: 14px
    width: 14px
    position: absolute
    left: 65%
    // top: 50%
    margin: 11px 0px 0px -12px
    transform: rotateX(55deg)
    z-index: -2
    &:after
      content: ""
      border-radius: 50%
      height: 40px
      width: 40px
      position: absolute
      margin: -13px 0 0 -13px
      animation: pulsate 1s ease-out
      animation-iteration-count: infinite
      opacity: 0.0
      box-shadow: 0 0 1px 2px #B2A59B
      animation-delay: 1.1s

@keyframes pulsate
  0%
    transform: scale(0.1, 0.1)
    opacity: 0.0
  50%
    opacity: 1.0
  100%
    transform: scale(1.2, 1.2)
    opacity: 0

@keyframes rotateImg
  100%
    transform: rotateY(360deg)

@keyframes bounce
  0%
    opacity: 0
    transform: translateY(-2000px) rotate(-45deg)
  60%
    opacity: 1
    transform: translateY(30px) rotate(-45deg)
  80%
    transform: translateY(-10px) rotate(-45deg)
  100%
    transform: translateY(0) rotate(-45deg)


.selected-container
  .pin
    background: #EF4040
  .pulse
    &:after
      box-shadow: 0 0 1px 2px #EF4040

</style>
