var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "map-marker-container",
      class: _vm.isSelected ? "selected-container" : "",
    },
    [
      _vm.isImg
        ? _c(
            "div",
            { staticClass: "imgPin", class: _vm.blink ? "animatedBadge" : "" },
            [_c("q-img", { staticClass: "img", attrs: { src: _vm.imgSrc } })],
            1
          )
        : _c("div", { staticClass: "pin" }),
      _c("div", { class: _vm.isImg ? "imgPulse" : "pulse" }),
      _vm._t("innerMark"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }